import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, createBrowserRouter, RouterProvider, Route, Outlet} from 'react-router-dom';
import TestPage from './components/TestPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([{
  path: "/",
  element: (<div><Outlet /></div>),
  children: [
    {path: "/",
      element: <div>HOMEPAGE</div>
    },
    {path: "/test",
      element: <TestPage />
    }
  ]
}
]
)

root.render(
  <React.StrictMode>
    <div>SZIA GERI</div>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
